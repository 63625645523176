#cart-title{
	margin-bottom: 20px;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
}
.quantityBtn {
align-items: center;
    display: inherit;
}
.quantityBtn button {
    height: 30px;
}
.removeCol {
    align-items: center;
    display: inherit;
}
