
#cartNumber {
	font-size: 13px;
	color: black;
	font-weight: bold;
	position: absolute;
}
.total {
      bottom: 0px;
    border-top: 1px solid black;
    position: absolute;
    width: 100%;
}
.total span {
    position: absolute;
    right: 20px;
}
